<template>
  <div class="landing-page__banner position-relative rounded">
    <strapi-image
      class="landing-page__banner-image position-absolute top-0 start-0 w-100 h-100 rounded"
      :image="component.customComponent.backgroundImage"
      :image-class="'w-100 h-100 object-cover rounded'"
      :lazy="false"
    />
    <div
      class="position-relative p-4 p-md-8 row align-items-center justify-content-between"
    >
      <div class="col-12 col-md col-lg-6">
        <h2 class="mb-0">{{ component.customComponent.title }}</h2>
        <p class="mt-4 landing-page__banner-info">
          <strong>{{ component.customComponent.content }}</strong>
        </p>
        <a
          v-for="btn in component.customComponent.buttons"
          :key="btn.id"
          :class="`btn btn-sm mt-4 px-8 btn-${btn.type}`"
          :href="btn.link"
          :target="btn.newTab ? '_blank' : '_self'"
        >
          {{ btn.text }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { StrapiCustomComponent } from '~/apollo/types/types';

defineProps({
  orderUrl: {
    type: String,
    required: true,
  },
  component: {
    type: Object as PropType<StrapiCustomComponent>,
    required: true,
  },
});

const { t } = useI18n();
</script>
<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.landing-page__banner {
  min-height: 12rem;
  &-image {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 25%);
  }
  &-info {
    letter-spacing: 1.5px;
  }
}
</style>
